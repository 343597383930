<script>
import {mapActions} from "vuex";
import CartTable from "@/components/CartTable.vue";

export default {
  name: "CartModal",
  components: {CartTable},
  computed: {
    show: {
      get(){
        return this.$store.state.cart.showCartModal
      },
      set(value){
        this.setShowCartModal(value)
      }
    }
  },
  methods:{
    ...mapActions('cart', [
        'setShowCartModal'
    ])
  }
}
</script>

<template>
  <Modal wrapperClass="modal-cart" v-model="show" title="Корзина" :modal-class="'modal modal-xl'">
    <cart-table></cart-table>
  </Modal>
</template>
<style lang="scss">
  .modal-cart{
    .vm-content{
      padding: 0;
    }
  }
</style>