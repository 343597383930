<template>
  <div class="table-wrapper">
    <table class="hidden md:table" v-if="!isEmpty">
      <thead>
        <tr>
          <th>Фото</th>
          <th>Модель</th>
          <th>Цвет</th>
          <th>Размер</th>
          <th>Цена</th>
          <th>Кол-во</th>
          <th>Стоимость</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="product_row" v-for="(product, index) in products">
          <td class="product_row__image"><img :src=product.productColor.pictureProduct alt=""></td>
          <td @click="$router.push('/cart')" v-html="product.name"></td>
          <td>{{product.productColor.name}}</td>
          <td>{{product.size.name.replace('-', '/')}}</td>
          <td>{{new Intl.NumberFormat().format(product.price) + ' руб.'}}</td>
          <td><span class="mr-1 cursor-pointer" @click="decrementProduct(index)">-</span>{{product.count}}<span class="ml-1 cursor-pointer" @click="incrementProduct(index)">+</span></td>
          <td>{{new Intl.NumberFormat().format(product.price * product.count) + ' руб.'}}</td>
          <td class="product_row__action flex flex-col justify-between items-center py-1.5">
            <font-awesome-icon @click="deleteProduct(index)" :icon="['fas', 'xmark']" class="text-3xl pointer px-2"/>
            <font-awesome-icon :icon="['far', 'pen-to-square']" class="text-2xl"/>
          </td>
        </tr>
      <tr class="total-row">
        <td colspan="5">ВСЕГО:</td>
        <td class="text-right pr-2">{{totalCount}} шт</td>
        <td class="text-right pr-2">{{totalSum}}</td>
      </tr>
      <tr class="discount-row">
        <td colspan="2">Скидка от суммы чека (без учёта уценённого и акцизного товара)*</td>
        <td colspan="1">10%</td>
        <td colspan="3"></td>
        <td class="text-right pr-2">0 руб.</td>
      </tr>
      <tr class="total-pay">
        <td colspan="6">Итого к оплате</td>
        <td class="text-right pr-2">{{totalSum}}</td>
      </tr>
      </tbody>
    </table>
    <table class="md:hidden" v-if="!isEmpty">
      <thead>
      <tr>
        <th>Модель/Цвет/Размер</th>
        <th>Цена</th>
        <th>Кол-во</th>
        <th>Стоимость</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr class="product_row" v-for="(product, index) in products">
        <td @click="$router.push('/cart')" v-html="product.name + ' ' + product.productColor.name + ' ' + product.size.name.replace('-', '/')"></td>
        <td>{{new Intl.NumberFormat().format(product.price) + ' руб.'}}</td>
        <td><span class="mr-1 cursor-pointer" @click="decrementProduct(index)">-</span>{{product.count}}<span class="ml-1 cursor-pointer" @click="incrementProduct(index)">+</span></td>
        <td>{{new Intl.NumberFormat().format(product.price * product.count) + ' руб.'}}</td>
        <td class="product_row__action ">
          <font-awesome-icon @click="deleteProduct(index)" :icon="['fas', 'xmark']" class="text-xl px-1.5"/>
        </td>
      </tr>
      <tr class="total-row">
        <td colspan="2">ВСЕГО:</td>
        <td class="text-right pr-2">{{totalCount}} шт</td>
        <td class="text-right pr-2">{{totalSum}}</td>
      </tr>
      <tr class="discount-row">
        <td colspan="2">Скидка от суммы чека (без учёта уценённого и акцизного товара)*</td>
        <td colspan="1">10%</td>
        <td class="text-right pr-2">0 руб.</td>
      </tr>
      <tr class="total-pay">
        <td colspan="3">Итого к оплате</td>
        <td class="text-right pr-2">{{totalSum}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'CartTable',
  data(){
    return {

    }
  },
  components: {

  },
  computed: {
    ...mapGetters('cart', [
        'isEmpty',
        'products',
        'totalSum',
        'totalCount'
    ])
  },
  methods: {
    ...mapActions('cart', [
      'deleteProduct',
      'incrementProduct',
      'decrementProduct'
    ])
  }
}
</script>

<style scoped lang="scss">
  .table-wrapper{
    table{
      width: 100%;
      border-spacing: 0;

      thead{
        th{
          &:first-child{
            border-left: 1px solid #C0C0C0;
          }
          &:last-child{
            background-color: transparent;
          }
          text-align: center;
          border-right: 1px solid #C0C0C0;
          border-bottom: 2px solid #ddd;
          background-color: #E3E3E3;
          padding: 8px;
        }
      }

      tbody{
        .product_row{
          td{
            &:first-child{
              border-left: 1px solid #C0C0C0;
            }

            border-right: 1px solid #C0C0C0;
            border-bottom: 1px solid #C0C0C0;
            text-align: center;
          }

          .product_row__image{
            img {
              height: 70px;
            }
          }

          .product_row__action{
            svg{
              color: #e2e2e2;
            }
          }
        }

        .total-row{
          background: linear-gradient(90deg, rgba(252, 252, 252, 1) 0%, rgb(194, 194, 194) 100%);
          font-size: 14px;

          td{
            &:first-child{
              text-align: left;
            }
            &:last-child{
              border-right: 1px solid #C0C0C0;
              border-left: 1px solid #C0C0C0;
            }
          }
        }
        .discount-row{
          background: linear-gradient(90deg, rgba(252, 252, 252, 1) 0%, rgb(208, 212, 215) 100%);
          font-size: 14px;

          td{
            &:first-child{
              text-align: left;
            }
            &:nth-child(2){
              font-weight: bold;
            }
            &:last-child{
              border-right: 1px solid #C0C0C0;
              border-left: 1px solid #C0C0C0;
            }
          }
        }
        .total-pay{
          background: linear-gradient(90deg, rgba(244, 249, 243, 1) 0%, rgba(90, 185, 91, 1) 100%);
          font-size: 14px;

          td{
            &:first-child{
              text-align: left;
            }
            &:last-child{
              border-right: 1px solid #C0C0C0;
              border-left: 1px solid #C0C0C0;
            }
          }
        }
      }
    }
  }
</style>
