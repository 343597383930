<template>
  <div class="pl-4 pt-2">
    <Breadcrumb :model="items">
      <template #item="{ item, props }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
          <a :href="href" v-bind="props.action" @click="navigate">
            <span :class="[item.icon, 'text-color']" />
            <span class="text-primary font-semibold text-blue-link">{{ item.label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span class="text-surface-700 text-grey-link dark:text-surface-0">{{ item.label }}</span>
        </a>
      </template>
    </Breadcrumb>
  </div>
  <div class="cart-wrapper flex flex-col md:flex-row md:justify-between md:m-4">
    <div class="md:w-[70%]">
      <h2 class="p-2 text-3xl font-bold">Чек заказа <span class="text-lg font-normal">от 22.03.2024</span></h2>
      <cart-table></cart-table>
    </div>
    <div class="px-6 md:w-[30%]">
      <h4 class="font-bold text-2xl pl-2">Итоговая информация</h4>
      <p class="border-underline text-lg">Общее количество: {{totalCount}}</p>
      <p class="border-underline text-lg">Общий вес: {{totalWeight/1000}} кг.</p>
      <p class="border-underline text-lg">Итого по чеку: (без учета доставки) {{totalSum}}</p>
      <p class="border-underline text-lg">Стоимость доставки: Будет известна в момент оформления</p>
      <div class="flex justify-center mt-4 mb-4">
        <button class="btn-green" @click="showOrderForm = true" type="button">Оплатить и оформить</button>
      </div>
    </div>
  </div>
  <Modal wrapperClass="modal-order" v-model="showOrderForm" title="Оформление доставки заказа"
         :modal-class="'modal modal-xl'">
    <div class="order-form-wrapper flex">
      <div class="order-form-wrapper__first flex-1">
        <h4>1. Информация о заказчике</h4>
        <div class="order-form-wrapper__first-switcher">
          <fieldset>
            <legend>
              Статус клиента
            </legend>
            <div>
              <label>
                <input v-model="clientType" type="radio" name="client" checked value="new">
                Новый покупатель
              </label>
              <label>
                <input v-model="clientType" @click="ourClient" type="radio" name="client" value="our">
                Постоянный клиент
              </label>
            </div>
          </fieldset>
        </div>
        <div class="order-form-wrapper__first-fields">
          <div v-if="clientType === 'our'" class="pb-4">
            <div v-if="getLogin === true" class="flex justify-between">
              <p>{{email}}</p>
              <p @click="out">Выйти</p>
            </div>
            <label class="block">
              <span class="text-sm text-gray-500">Фамилия</span>
              <input v-model="last_name"
                     class="mt-1 block w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                     type="text" disabled>
            </label>
            <div class="flex">
              <label class="mr-2 block w-1/2">
                <span class="text-sm text-gray-500">Имя</span>
                <input v-model="first_name"
                       class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                       type="text" disabled>
              </label>
              <label class="ml-2 block w-1/2">
                <span class="text-sm text-gray-500">Отчество</span>
                <input v-model="middle_name"
                       class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                       type="text" disabled>
              </label>
            </div>
            <label class="block">
              <span class="text-sm text-gray-500">Фамилия</span>
              <input v-model="phone"
                     class="mt-1 block w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                     type="text" disabled>
            </label>
            <label class="block">
              <span class="text-sm text-gray-500">Фамилия</span>
              <input v-model="email"
                     class="mt-1 block w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                     type="text" disabled>
            </label>
          </div>
          <div v-else>
            <fieldset>
              <legend>
                Грузополучатель
              </legend>
              <div>
                <label class="block">
                  <span class="text-sm text-gray-500">Фамилия</span>
                  <input v-model="new_client.last_name"
                         class="mt-1 block w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                         type="text">
                </label>
                <div class="flex">
                  <label class="mr-2 block w-1/2">
                    <span class="text-sm text-gray-500">Имя</span>
                    <input v-model="new_client.first_name"
                           class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                           type="text">
                  </label>
                  <label class="ml-2 block w-1/2">
                    <span class="text-sm text-gray-500">Отчество</span>
                    <input v-model="new_client.middle_name"
                           class="w-full mt-1 block h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                           type="text">
                  </label>
                </div>
              </div>
            </fieldset>
            <label class="block">
              <span class="text-sm text-gray-500">Телефон</span>
              <input v-model="new_client.phone"
                     class="mt-1 block w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                     type="text">
            </label>
            <label class="block">
              <span class="text-sm text-gray-500">Email</span>
              <input v-model="new_client.email"
                     class="mt-1 block w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                     type="text">
            </label>
            <div class="pl-3 pt-2">
              <h5 class="text-base mb-2">Что дает регистрация?</h5>
              <ul class="text-sm pb-2">
                <li>- Создание личного кабинета</li>
                <li>- Накопление бонусных баллов и оплата ими покупок</li>
                <li>- Быстрое оформление заказа автозаполнением полей</li>
                <li>- Отслеживание истории покупок</li>
                <li>- Быть в курсе всех новостей и разработок компании</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
      <div class="order-form-wrapper__second flex-1">
        <h4>2. Способ доставки</h4>
        <div>
          По согласованию с менеджером
        </div>
      </div>
      <div class="order-form-wrapper__third flex-1">
        <h4>3. Адрес доставки</h4>
        <div class="px-4">
          <label class="block">
            <span class="text-sm text-gray-500">Адрес</span>
            <input @input="sug" v-model="addressQuery"
                   class="text-sm mt-1 block w-full h-9 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                   type="text"
                    placeholder="Начните вводить адрес">
          </label>
        </div>
        <p @click="setAddress(address)" v-for="address in sugList">{{address}}</p>
      </div>
    </div>
    <div class="flex">
      <div class="flex-1">

      </div>
      <div class="flex-1">

      </div>
      <div class=" flex flex-1 justify-center p-2">
        <button @click="claim" class="btn-green">Оплатить</button>
      </div>
    </div>
  </Modal>
</template>
<script>
import CartTable from "@/components/CartTable";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import Breadcrumb from 'primevue/breadcrumb';

export default {
  name: 'CartView',
  data() {
    return {
      showOrderForm: false,
      clientType: 'new',
      new_client: {
        last_name: '',
        first_name: '',
        middle_name: '',
        email: '',
        phone: ''
      },
      addressQuery: "",
      address: "",
      deliveryType: 1,
      sugResult: null
    }
  },
  computed: {
    ...mapGetters('cart', [
        'products',
        'totalSum',
        'totalCount',
        'totalWeight'
    ]),
    ...mapGetters('user', [
      'getLogin'
    ]),
    last_name: {
      get() {
        return this.$store.state.user.data.last_name
      },
      set(value) {
        this.changeData({
          key: 'last_name',
          value: value
        })
      }
    },
    first_name: {
      get() {
        return this.$store.state.user.data.first_name
      },
      set(value) {
        this.changeData({
          key: 'first_name',
          value: value
        })
      }
    },
    middle_name: {
      get() {
        return this.$store.state.user.data.middle_name
      },
      set(value) {
        this.changeData({
          key: 'middle_name',
          value: value
        })
      }
    },
    email: {
      get() {
        return this.$store.state.user.data.email
      },
      set(value) {
        this.changeData({
          key: 'email',
          value: value
        })
      }
    },
    phone: {
      get() {
        return this.$store.state.user.data.phone
      },
      set(value) {
        this.changeData({
          key: 'phone',
          value: value
        })
      }
    },
    sugList(){
      if(this.sugResult === null){
        return false
      }
      return this.sugResult.suggestions.map(function (item){return item.value})
    },
    items(){
      let items = [];
      items.push({
        'label': 'Чек заказа'
      })
      items.push({
        'label': 'Корзина',
        'url': '/cart',
        'route': '/cart'
      })
      items.push({
        'label': 'Главная',
        'url': '/',
        'route': '/'
      })

      return items.reverse()
    }
  },
  methods: {
    ...mapActions('user', [
      'changeData',
      'logout'
    ]),
    ...mapActions('app', [
        'setShowAuthForm'
    ]),
    ourClient(){
      if(this.getLogin === false){
        this.setShowAuthForm(true)
      }
    },
    out(){
      this.logout()
      this.clientType='new'
    },
    claim(){
      let baseUrl = process.env.VUE_APP_API_BASE_URL
      let user = {}
      if(this.clientType === 'our'){
        user = {
          last_name: this.last_name,
          first_name: this.first_name,
          middle_name: this.middle_name,
          phone: this.phone,
          email: this.email
        }
      }else{
        user = this.new_client
      }
      axios.post(baseUrl + '/1.0/order', {products: this.products, user: user, address: this.address})
          .then(response => {

          })
    },
    sug(event){
      let value = event.target.value;
      if(value.length > 3){
        axios.post('http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', {query: value}, {
          headers: {
            "Authorization": "Token " + process.env.VUE_APP_DADATA_TOKEN
          }
        }).then(response => {
          this.sugResult = response.data
        })
      }
    },
    setAddress(value){
      this.address = value
      this.addressQuery = value
      this.sugResult = null
    }
  },
  components: {
    CartTable,
    Breadcrumb
  },
  created() {
    if(this.getLogin === true){
      this.clientType = 'our'
    }
  }
}
</script>

<style lang="scss" scoped>
.cart-wrapper {
  border: 1px solid #DDDDDD;

  > div {
    &:first-child {
      background-color: #fff;
    }

    &:last-child {
      background-color: #fff;
      border-left: 2px #ddd dashed;

      .border-underline{
        border-bottom: 1px solid #BEBEBE;
        padding: 5px 10px 2px 10px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
  }
}

.order-form-wrapper {
  border-bottom: 1px solid #B3B3B3;

  > div {
    &:last-child {
      border-left: 1px solid #B3B3B3;
    }

    > h4 {
      display: flex;
      align-items: center;
      background: #F0F0F0;
      font-size: 16px;
      font-weight: 600;
      padding: 5px 0 5px 10px;
      border-bottom: 1px solid #B3B3B3;
      margin-bottom: 10px;
    }
  }

  .order-form-wrapper__first {
    border-right: 1px solid #B3B3B3;

    .order-form-wrapper__first-switcher {
      padding: 0 10px;

      fieldset {
        border: 1px solid threedface;
        padding: 10px;
        margin-bottom: 10px;

        legend {
          display: block;
          width: auto;
          padding: 0 5px 0 10px;
          line-height: inherit;
          border-bottom: none;
          margin: 0 auto;
          position: relative;
          text-align: center;
          font-size: 14px;
          color: #929292;
        }

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
    }

    .order-form-wrapper__first-fields {
      padding: 0 10px;

      fieldset {
        border: 1px solid threedface;
        padding: 10px;
        margin-bottom: 10px;

        legend {
          display: block;
          width: auto;
          padding: 0 5px 0 10px;
          line-height: inherit;
          border-bottom: none;
          margin: 0 auto;
          position: relative;
          text-align: center;
          font-size: 14px;
          color: #929292;
        }
      }
    }
  }
}
.btn-green{
  width: 90%;
  border: 3px solid #259414;
  border-radius: 50px;
  color: #259414;
  font-weight: 600;
  text-align: center;
  padding-bottom: 5px;
  font-size: 30px;
  &:hover{
    text-decoration: none;
    color: white;
    background-color: #259414;
  }
}
</style>
<style lang="scss">
.modal-order {
  .vm {
    border-radius: 5px;
  }

  .vm-titlebar {
    background: url("@/assets/header_bg.jpg");
    color: white;
    border-radius: 5px 5px 0 0;
  }

  .vm-content {
    padding: 0;
  }
}
</style>