<template>
  <div class="pl-4 py-0.5">
    <Breadcrumb :model="items">
      <template #item="{ item, props }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
          <a :href="href" v-bind="props.action" @click="navigate">
            <span :class="[item.icon, 'text-color']" />
            <span class="text-primary font-semibold text-blue-link">{{ item.label }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span class="text-surface-700 text-grey-link dark:text-surface-0">{{ item.label }}</span>
        </a>
      </template>
    </Breadcrumb>
  </div>
  <product-card v-if="product" :product="product"></product-card>
</template>
<script>
import router from "@/router";
import {useToast} from 'vue-toastification'
import ProductCard from "@/components/ProductCard.vue";
import {mapGetters} from "vuex";
const toast = useToast()
import Breadcrumb from 'primevue/breadcrumb';

export default {
  name: 'ProductView',
  components: {
    ProductCard,
    Breadcrumb
  },
  data: () => ({
    productAlias: null
  }),
  computed: {
    ...mapGetters('app', [
        'products'
    ]),
    product(){
      return this.products.find(item => item.alias === this.productAlias);
    },
    items(){
      if(this.product === undefined){
        return [];
      }
      let items = [];
      items.push({
        'label': this.product.name.replace(/(<([^>]+)>)/ig, '')
      })
      items.push({
        'label': this.product.categories[0].name,
        'url': '/catalog/' + this.product.categories[0].alias,
        'route': '/catalog/' + this.product.categories[0].alias,
      })
      items.push({
        'label': 'Главная',
        'url': '/',
        'route': '/',
      })

      return items.reverse()
    }
  },
  created() {
    this.productAlias = router.currentRoute.value.params.product_alias
  },
}
</script>