<template>
  <div class="size-grid-wrapper min-h-[145px]">
    <table v-if="hasVariation" class="relative" @click.prevent.capture="chooseSize">
      <tr>
        <th><img class="color-image" :src="colorImage" alt=""></th>
        <th v-for="size in horizontalSizes">{{size}}</th>
      </tr>
      <tr v-for="verticalSize in verticalSizes">
        <th>{{verticalSize}}</th>
        <td v-if="hasSizesForCurrentColor" v-for="horizontalSize in horizontalSizes" :data-size-index="horizontalSize+verticalSize">{{getCountForSize(horizontalSize+verticalSize)}}</td>

      </tr>
      <p class="absolute top-[44%] left-[37%] text-xl" v-if="!hasSizesForCurrentColor">Расцветки нет в продаже</p>
    </table>
    <p v-else class="h-[145px] flex items-center justify-center text-xl">Изделия нет в продаже</p>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'SizeGrid',
  data(){
    return {
      sizeForSelect: {}
    }
  },
  props:{
    colorId: {
      type: Number,
      required: true
    },
    dimensionalGridId: {
      required: true
    },
    numberOfSizes: Object,
    colorImage: String,
    sizeIndex: String
  },
  computed:{
    ...mapGetters('app', [
      'dimensionalGrids'
    ]),
    sizes(){
      if(this.dimensionalGrids.length !== 0 && this.dimensionalGridId){
        return this.dimensionalGrids[this.dimensionalGridId]['sizes']
      }

      return [];
    },
    verticalSizes(){
      if(this.sizes){
        return [...new Set(Object.values(this.sizes).map(a => a.first_parameter))]
      }
      return []
    },
    horizontalSizes(){
      if(this.sizes){
        return [...new Set(Object.values(this.sizes).map(a => a.second_parameter))]
      }
      return []
    },
    hasSizesForCurrentColor() {
      if (!this.numberOfSizes || !this.colorId) return false;

      return Object.keys(this.numberOfSizes).some(key => {
        return key.startsWith(`${this.colorId}_`);
      });
    },
    hasVariation(){
      return Object.keys(this.numberOfSizes).length > 0;
    }
  },
  watch: {
    colorId() {
      this.sizeForSelect = {}
      this.$emit('chooseSize', {'sizes': this.sizeForSelect, 'id': "0", 'index': "0"})
    },
    sizeIndex(newValue){
      const elements = document.querySelectorAll(`[data-size-index="${newValue}"]`);
      let sizes = document.getElementsByClassName('activeSize')
      Array.from(sizes).forEach(function (size){
        return size.classList.remove('activeSize')
      })
      elements.forEach(element => {
        element.classList.add("activeSize");
      });
    }
  },
  methods: {
    getCountForSize(sizeIndex){
      if(this.sizes.hasOwnProperty(sizeIndex)){
        let colorId = String(this.colorId)
        let sizeId = String(this.sizes[sizeIndex].id)
        let property = colorId+'_'+sizeId
        if(this.numberOfSizes.hasOwnProperty(property)){
          let count = this.numberOfSizes[property].count
          this.sizeForSelect[sizeIndex] = this.sizes[sizeIndex]
          return count;
        }
      }
      return '-';
    },
    chooseSize(event){
      this.sizeForSelect = {}
      let el = event.target
      let sizeIndex = el.getAttribute('data-size-index')
      if(sizeIndex === null){
        return
      }
      if(
          this.sizes.hasOwnProperty(sizeIndex) &&
          this.getCountForSize(sizeIndex) !== '-'
      ){
        this.$emit('chooseSize', {'sizes': this.sizeForSelect, 'id': this.sizes[sizeIndex].id, 'index': sizeIndex})
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .size-grid-wrapper{
    width: 100%;

    table{
      width: 100%;
      border: 1px;
      border-collapse: collapse;
      text-align: center;
      font-size: 14px;
      background: #F3F3F3;

      tr{
        &:first-child{
          th{
            &:first-child{
              width: 50px;
              height: 23px;
            }
          }
        }
      }

      th{
        background-color: #D8D8D8;
        border: 1px solid white;
        font-weight: 400;
        text-align: center;
      }

      td{
        background-color: #F3F3F3;
        border: 1px solid white;
        height: 20px;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
      }

      .color-image{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .activeSize{
        background-color: #D8D8D8 !important;
      }
    }
  }

  .td-plug{
    background-color: #F3F3F3 !important;
    border: 1px solid #F3F3F3 !important;
    font-weight: 400 !important;
    text-align: center !important;
  }
</style>
